import { Col, Form, Input, InputNumber, Row, Select } from "antd";
import React, { useEffect } from "react";
import { fetchMunicipios, fetchProvincias } from "catastro/functions";
import { useLocation } from "react-router-dom";


function Direccion({ tipo, required }: { tipo: string, required: boolean }) {
    const [provincias, setProvincias] = React.useState<string[]>([])
    const [municipios, setMunicipios] = React.useState<string[]>([])
    const onProvinciaChange = (value: string) => {
        fetchMunicipios(value).then(municipios => setMunicipios(municipios))
    };
    useEffect(() => {
        fetchProvincias().then(provincias => setProvincias(provincias))
    }, [])
    const { Option } = Select;
    function useQuery() {
        const { search } = useLocation();

        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    let query = useQuery();
    return (
        <>
            <Row>
                <Col span={13}>
                    <Form.Item name={"calle" + tipo} initialValue={query.get("facturacionDireccion")} label="Dirección" rules={[{ required: !required, message: "Razon social requerida" }]}>
                        <Input disabled={required}></Input>
                    </Form.Item></Col>
                <Col span={5}>
                    <Form.Item name={"numero" + tipo} initialValue={parseInt(query.get("facturacionNumero") || "") || null} label="Nº/KM" style={{ marginLeft: 15 }} rules={[{ required: !required, message: "Número positivo requerido", type: 'number', min: 0, max: 99999 }]}>
                        <InputNumber disabled={required}></InputNumber>
                    </Form.Item></Col>
                <Col span={6}>
                    <Form.Item name={"codigopostal" + tipo} initialValue={parseInt(query.get("facturacionCP") || "") || null} label="Código postal" style={{ marginLeft: 15 }} rules={[{ required: !required, message: "Código postal requerido", type: 'number', min: 0, max: 99999 }]}>
                        <InputNumber disabled={required}></InputNumber>
                    </Form.Item></Col>
            </Row>
            <Row>
                <Col span={12}>
                    <Form.Item name={"provincia" + tipo} label="Provincia" initialValue={query.get("facturacionMunicipio")} rules={[{ required: !required, message: "Provincia requerido" }]}>
                        <Select showSearch
                            placeholder="Seleccionar provincia"
                            onChange={onProvinciaChange}
                            allowClear
                            disabled={required}
                        >
                            {provincias.map((provincia) => (
                                <Option key={provincia} value={provincia}>{provincia}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name={"municipio" + tipo} label="Municipio" initialValue={query.get("facturacionProvincia")} style={{ marginLeft: 15 }} rules={[{ required: !required, message: "Municipio requerido" }]}>
                        <Select showSearch
                            placeholder="Seleccionar municipio"
                            allowClear
                            disabled={required}
                        >
                            {municipios.map((municipio) => (
                                <Option key={municipio} value={municipio}>{municipio}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row></>
    );
}

export default Direccion;