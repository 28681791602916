import React from "react";
import { IResourceComponentsProps, useShow, useOne } from "@refinedev/core";
import { Show, TagField, TextField, DateField } from "@refinedev/antd";
import { Typography } from "antd";

const { Title } = Typography;

export const RegistroShow: React.FC<IResourceComponentsProps> = () => {
    const { queryResult } = useShow();
    const { data, isLoading } = queryResult;

    const record = data?.data;

    return (
        <Show isLoading={isLoading} title="Detalles de registro:" canDelete>
            <Title level={5}></Title>
            <Title level={5}>Referencia Catastral</Title>
            <TextField value={record?.referencia_catastral} />
            <Title level={5}>Razón social</Title>
            <TextField value={record?.nombre_cliente} />
            <Title level={5}>NIF</Title>
            <TextField value={record?.nif_cliente} />
            <Title level={5}>Direccion facturación</Title>
            <TextField value={record?.direccion_cliente} />
            <Title level={5}>CP facturación</Title>
            <TextField value={record?.codigopostal_cliente} />
            <Title level={5}>Ciudad facturación</Title>
            <TextField value={record?.ciudad_cliente} />
            <Title level={5}>Tasa de registro</Title>
            <TextField value={record?.tasa_registro} />
            <Title level={5}>Fecha de registro</Title>
            <DateField value={record?.timestamp} format={"DD/MM/YYYY"}/>
            <Title level={5}>Tipo Edificio</Title>
            <TextField value={record?.tipo_edificio}/>
            <Title level={5}>Tipo Factura</Title>
            <TextField value={record?.tipo_factura} />
        </Show>
    );
};
