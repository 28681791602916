

import { Result, Typography } from "antd";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import logo from "../../images/logo.svg"
export const Landing: React.FC = () => {
    useEffect(() => {
        document.title = 'Sitio en construcción - certinae SL';
    }, [])
    return (
       //Pagina en contruccion 
       <Result
       status="warning"
       title="Sitio en construcción"
       extra={
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>certinae SL</title>
            <meta property="og:title" content="certinae SL" />
        </Helmet>
          <Typography.Text>
            Lamentamos las molestias, estamos trabajando en ello. 
            <br />
            Para más información, contáctanos en{" "}
            <a href="mailto:contacto@certinae.es">contacto@certinae.es</a>
          </Typography.Text>
          <br />
          <br />
          <br />
          <img src={logo} width="200px" alt="Logo" />
        </>
      }
     />
    );
};
