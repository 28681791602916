import React, { useEffect } from "react";
import { IResourceComponentsProps, BaseRecord, useMany } from "@refinedev/core";
import {
    useTable,
    List,
    ShowButton,
    TagField,
    DateField,
    BooleanField,
    ImageField,
} from "@refinedev/antd";
import { Table, Space, Button, Form, Input, Row, Col } from "antd";
import { SyncOutlined } from "@ant-design/icons";
import axios from "axios";

export const IngresoList: React.FC<IResourceComponentsProps> = () => {
    const [loading, setLoading] = React.useState<boolean>(false)
    const [isNotAlejandro, setIsNotAlejandro] = React.useState<boolean>(true)
    const { tableProps, searchFormProps } = useTable({
        syncWithLocation: true,
        pagination: {
            pageSize: 10,
        },
        onSearch: (values: any) => {
            return [
                {
                    field: "search",
                    operator: "contains",
                    value: values.search,
                },
            ];
        },
    });

    useEffect(() => {
        if (localStorage.getItem("email") === "alejandro@boye.ro") {
            setIsNotAlejandro(false)
        }
    }, [])

    const onSincronizar = (values: any) => {
        
        setLoading(true)
        axios({
            url: process.env.REACT_APP_API_URL + '/plaid/api/transactions',
            method: 'GET',
            timeout: 90000,
            auth: {
                username: localStorage.getItem("email") || "",
                password: localStorage.getItem("password") || "",
            }
        }).then((response) => {
            setLoading(false)
            window.location.reload()
        }).catch(async (res) => {
            setLoading(false)

        })
    };

    return (
        <List>
            <Row>
                <Col span={12}>
                    <Form {...searchFormProps} layout="inline" style={{ marginBottom: 15 }}>
                    <Form.Item name="search">
                    <Input placeholder=" Introducir busqueda" />
                </Form.Item>
                <Button type="primary" onClick={searchFormProps.form?.submit}>Buscar</Button>
                    </Form>
                </Col>
                <Col span={12}>
                    <Form.Item style={{ float: "right" }} >
                        <Button id="sync" type="default" hidden={isNotAlejandro} onClick={onSincronizar} loading={loading}>
                           Sincronizar
                        </Button>
                    </Form.Item>
                </Col>
            </Row>

            <Table {...tableProps} rowKey="transactionid" pagination={{
                ...tableProps.pagination,
                position: ["bottomCenter"],
                size: "default",
                showQuickJumper: {
                    goButton: <Button type="primary" style={{ marginLeft: 10 }}>Ir</Button>,
                },
            }}>
                <Table.Column
                    dataIndex={["date"]}
                    title="Fecha"
                    render={(value: any) => <DateField value={value} format={"DD/MM/YYYY"} />}
                />

                <Table.Column dataIndex="name" title="Descripción" />

                <Table.Column
                    dataIndex={["pending"]}
                    title="Pendiente"
                    render={(value: any) => <BooleanField value={value} />}
                />

                <Table.Column dataIndex="amount" title="Cantidad"
                    render={(value: any) => <p>{value.toString().replace("-", "")} €</p>}
                />
            </Table>
        </List>
    );
};
