
import { Show } from "@refinedev/antd";

import { Typography, Form, Button, Input, Divider, UploadFile, UploadProps } from "antd";
import layout from "antd/es/layout";
import React, { useEffect } from "react";
import Dragger from "antd/es/upload/Dragger";
import { InboxOutlined } from "@ant-design/icons";
import { RcFile } from "antd/es/upload";
import axios from "axios";

const { Title } = Typography;
export const EnviarEmail: React.FC = () => {
    const [form] = Form.useForm();
    const [mensaje, setMensaje] = React.useState<string>("Ningún proceso en curso")
    const [loading, setLoading] = React.useState<boolean>(false)
    const [fileList, setFileList] = React.useState<UploadFile[]>([]);

    useEffect(() => {

    }, [])

    const onEnviar = (values: any) => {
        
        let payload = {
            "referencia_catastral": values.rc,
            "email": values.emailempresa,
        }
        const formData = new FormData();
        formData.append("userData", JSON.stringify(payload))
        fileList.forEach((file) => {
            formData.append('files[]', file as RcFile);
        });

        setLoading(true)
        setMensaje("Enviando email...")

        axios({
            url: process.env.REACT_APP_API_URL + '/enviarEmail/Certificado/',
            method: 'POST',
            data: formData,
            timeout: 90000,
            responseType: 'blob',
            auth: {
                username: localStorage.getItem("email") || "",
                password: localStorage.getItem("password") || "",
            }
        }).then((response) => {
            setLoading(false)
            setMensaje("Email enviado correctamente")
            

        }).catch(async (res) => {
            setLoading(false)
            let responseObj = await res.response.data.text();
            setMensaje("(error) " + JSON.parse(responseObj).error)
        })
    };

    const props: UploadProps = {
        name: 'file',
        multiple: true,
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            setFileList((preFileList) => [...preFileList, file]);
            return false;
        },
        fileList: fileList,
    };


    return (
        <Form
            {...layout}
            form={form}
            name="control-hooks"
            style={{ maxWidth: 1000 }}
            autoComplete="on"
            onFinish={onEnviar}
        >
            <Show
                title="Enviar certificado"
                headerProps={{
                    backIcon: false,
                }}
                headerButtons={<></>}
                
            >
                <Title level={5}>Referencia catastral:</Title>
                <Form.Item name="rc" label="" rules={[{ required: true, message: "Referencia catastral requerida" }, { len: 20, message: "La referencia catastral debe tener 20 caracteres" }]}>
                    <Input style={{ width: 265 }} />
                </Form.Item>
                <Title level={5}>Email:</Title>
                <Form.Item name="emailempresa" initialValue="" label="" rules={[{ required: true, message: "Email requerido", type: "email" }]}>
                    <Input style={{ width: 265 }} />
                </Form.Item>
                <Title level={5}>Archivos a enviar:</Title>
                <Form.Item name="archivos" label="" rules={[
                        {
                            required: true,
                            message: '',
                        },
                        () => ({
                            validator(_, value) {
                                if (fileList.length >= 1) {
                                    let size = 0;
                                    fileList.forEach((file) => {
                                        size += file.size || 0;
                                    });
                                    if (size > 20000000) {
                                        return Promise.reject(new Error('El tamaño total de los archivos no puede superar los 20 MB'));
                                    }
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('Debe subir al menos un archivo'));
                            },
                        }),
                    ]}>
                        <Dragger {...props}>
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Haz click aqui, o arrastra los archivos a este area</p>
                            <p className="ant-upload-hint">
                            </p>
                        </Dragger>
                    </Form.Item>
            </Show>
            <Show title="" headerProps={{ backIcon: false }} breadcrumb={<></>} headerButtons={<></>}>
                <Title level={5}>{"Estado: " + mensaje}</Title>
                <Divider />
                <Form.Item  >
                    <Button loading={loading} id="generarButton" type="primary" htmlType="submit" >
                        Enviar
                    </Button>
                </Form.Item>
            </Show>
        </Form>

    );
};
