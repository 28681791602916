import axios from "axios"
import _ from "lodash"

async function fetchProvincias() : Promise<string[]> {
        return axios.get("https://ovc.catastro.meh.es/OVCServWeb/OVCWcfCallejero/COVCCallejero.svc/json/ObtenerProvincias", {
        })
            .then(response => {
                const provincias = response.data.consulta_provincieroResult.provinciero.prov
                let result = []
                for (let i = 0; i < provincias.length; i++) {
                    result.push(provincias[i].np)
                }
                return result
            })
            .catch(error => {
                console.log(error)
                return []
            }
            )
    }

async function fetchMunicipios(provincia:string) : Promise<string[]> {
        return axios.get("https://ovc.catastro.meh.es/OVCServWeb/OVCWcfCallejero/COVCCallejero.svc/json/ObtenerMunicipios?Provincia="+provincia, {
        })
            .then(response => {
                const municipios = response.data.consulta_municipieroResult.municipiero.muni
                let result = []
                for (let i = 0; i < municipios.length; i++) {
                    result.push(municipios[i].nm)
                }
                return result
            })
            .catch(error => {
                console.log(error)
                return []
            }
            )
    }

type boolresult = { msg:string, cpdetected:boolean, error:boolean }
async function fetchVerificarRC (provincia: string, municipio: string, rc: string): Promise<boolresult> {
    return axios.get("https://ovc.catastro.meh.es/OVCServWeb/OVCWcfCallejero/COVCCallejero.svc/json/Consulta_DNPRC?Provincia="+provincia+"&Municipio="+municipio+"&RefCat="+rc,  {
        })
            .then(response => {
                if (response.data.consulta_dnprcResult.control.cuerr as number) {
                    return { msg: response.data.consulta_dnprcResult.lerr[0].des, cpdetected: true, error: true } as boolresult
                }else{
                    let detected = false
                    if (response.data.consulta_dnprcResult.bico.bi.idbi.cn == "RU"){
                        if (_.has(response,"data.consulta_dnprcResult.bico.bi.dt.locs.lors.lourb.dp")){
                            detected = true
                        }
                    }else{
                        if (_.has(response,"data.consulta_dnprcResult.bico.bi.dt.locs.lous.lourb.dp") ){
                            detected = true
                        }
                    }
                    return { msg: response.data.consulta_dnprcResult.bico.bi.ldt, cpdetected: detected,error: false } as boolresult
                }
            })
            .catch(error => {
                return { msg: error, cpdetected: true, error: true } as boolresult
            })
    }

export {fetchMunicipios,fetchProvincias, fetchVerificarRC}