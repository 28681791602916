import { AuthPage } from "@refinedev/antd";
import { Authenticated } from "@refinedev/core";
import { NavigateToResource } from "@refinedev/react-router-v6";
import Layout from "components/layout";
import { HeadlessInferencer } from "@refinedev/inferencer/headless";
import { EnviarEmail } from "pages/email/email";
import { EnviarFactura } from "pages/email/emailFactura";
import { CrearFactura } from "pages/facturas/crearFactura";
import { Facturas } from "pages/facturas/facturas";
import { Landing } from "pages/landing/landing";
import { NuevoCertificado } from "pages/nuevo-certificado/nuevo-certificado";
import { NuevoCertificado2 } from "pages/nuevo-certificado/nuevo-certificado-2";
import { Solicitud } from "pages/solicitud/solicitud";
import { Route, Routes } from "react-router-dom";
import { AntdInferencer } from "@refinedev/inferencer/antd";
import { RegistroList } from "recursos/registros/list";
import { RegistroShow } from "recursos/registros/show";
import { RegistroCreate } from "recursos/registros/create";
import { RegistroEdit } from "recursos/registros/edit";
import { ClienteList } from "recursos/clientes/list";
import { ClienteShow } from "recursos/clientes/show";
import { ClienteCreate } from "recursos/clientes/create";
import { NOTFOUND } from "dns";
import { NotificationOutlined } from "@ant-design/icons";
import { ClienteEdit } from "recursos/clientes/edit";
import { SegundaOcupacion } from "pages/segundaocupacion/segundaocupacion";
import { SegundaOcupacion2 } from "pages/segundaocupacion/segundaocupacion-2";
import { EnviarSegundaOcupacion } from "pages/email/emailSegundaOcupacion";
import { IngresoList } from "recursos/ingresos/list";
import { SeguridadEstructural } from "pages/seguridadestructural/seguridadestructural";
import { SeguridadEstructural2 } from "pages/seguridadestructural/seguridadestructural-2";
import { EnviarSeguridadEstructural } from "pages/email/emailseguridadEstructural";

function AppRoutes() {
  //if localhost development or azure static web apps
  if (window.location.hostname === "www.certinae.es") {
    return (
      <Routes>
        <Route>
          <Route path="" element={<Landing />} />
        </Route>
        <Route>
          <Route path="solicitud">
            <Route index element={<Solicitud />} />
            <Route path=":id" element={<Solicitud />} />
          </Route>
        </Route>
      </Routes>
    );
  }
  else {
    return (
      <Routes>
        <Route element={
          <Authenticated key="dashboard" fallback={<AuthPage type="login" registerLink={<></>} forgotPasswordLink={<></>} title="" />}>
            {<Layout />}
          </Authenticated>
        }>
          <Route index element={<NuevoCertificado />} />
          <Route path="generarFacturas" element={<Facturas />} />
          <Route path="crearfactura" element={<CrearFactura />} />
          <Route path="enviaremail" element={<EnviarEmail />} />
          <Route path="enviarfactura" element={<EnviarFactura />} />
          <Route path="nuevo-certificado-2" element={<NuevoCertificado2 />} />
          <Route path="segundaocupacion" element={<SegundaOcupacion />} />
          <Route path="segundaocupacion-2" element={<SegundaOcupacion2 />} />
          <Route path="enviarsegundaocupacion" element={<EnviarSegundaOcupacion />} />
          <Route path="seguridadestructural" element={<SeguridadEstructural />} />
          <Route path="seguridadestructural-2" element={<SeguridadEstructural2 />} />
          <Route path="enviarseguridadestructural" element={<EnviarSeguridadEstructural />} />

          <Route path="facturas">
            <Route index element={<RegistroList />} />
            <Route path="show/:id" element={<RegistroShow />} />
            <Route path="edit/:id" element={<RegistroEdit />} />
            <Route path="delete/:id" element={<AntdInferencer />} />
            <Route path="create" element={<RegistroCreate />} />
          </Route>
          <Route path="clientes">
            <Route index element={<ClienteList />} />
            <Route path="show/:id" element={<ClienteShow />} />
            <Route path="edit/:id" element={<ClienteEdit />} />
            <Route path="delete/:id" element={<AntdInferencer />} />
            <Route path="create" element={<ClienteCreate />} />
          </Route>
          <Route path="ingresos">
            <Route index element={<IngresoList />} />
          </Route>
        </Route>
        <Route>
        <Route path="solicitud">
            <Route index element={<Solicitud />} />
            <Route path=":id" element={<Solicitud />} />
          </Route>
        </Route>
        <Route>
          <Route path="construccion" element={<Landing />} />
        </Route>
        <Route path="*" element={<Landing />} />
      </Routes>
    );
  }

}
export default AppRoutes;
