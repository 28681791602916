import {  ThemedLayoutV2, ThemedSiderV2, ThemedTitleV2 } from "@refinedev/antd";
import { Outlet } from "react-router-dom";
import { Header } from "components/header";
import { Helmet } from "react-helmet";


function Layout() {
    return (
        <><Helmet>
            <meta charSet="utf-8" />
            <meta property="og:title" content="certinae SL" />
        </Helmet><ThemedLayoutV2 Header={Header} Title={({ collapsed }) => (
            <ThemedTitleV2 collapsed={collapsed} text="Panel de control certinae S.L" /* icon="🧾" */ />
        )}>

                <Outlet />
            </ThemedLayoutV2></>
    );
}

export default Layout;