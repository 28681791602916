
import { Show } from "@refinedev/antd";

import { Typography, Form, Input, Button, Select, Row, Col, Space, Checkbox, AutoComplete, Upload, UploadProps, message, InputNumber } from "antd";
import layout from "antd/es/layout";
import axios from "axios";
import { MasIcon, MenosIcon } from "components/icons";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { UploadOutlined } from "@ant-design/icons";
import { VendorOption, fetchVendors } from "API/functions";
import { isValidLegalEntityNif, isValidNaturalPersonNif } from "nif-dni-nie-cif-validation";
import Direccion from "components/direccion";


const { Title, Text } = Typography;
const { Option } = Select;
export const SeguridadEstructural2: React.FC = () => {
    const [form] = Form.useForm();
    //retrieve query params
    function useQuery() {
        const { search } = useLocation();

        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    let query = useQuery();
    let provincia = query.get("provincia")
    let municipio = query.get("municipio")
    let cp = query.get("cp")
    let rc = query.get("rc")
    //set number of cubiertas
    const [loading, setLoading] = useState<boolean>(false);
    const [registrarDisabled, setRegistrarDisabled] = useState<boolean>(false);
    const [usarDatosCliente, setUsarDatosCliente] = React.useState<boolean>(query.get("facturacionNIF") === null ? true : false)
    const [registrarloading, setregistrarloading] = useState<boolean>(false);
    const [mensaje, setMensaje] = React.useState<string>()

    const [vendors, setVendors] = React.useState<VendorOption[]>()

    //CustomValidation
    const [customFeedback, setCustomFeedback] = React.useState<boolean>(false)

    useEffect(() => {
        setMensaje("Ningun proceso en curso")
        fetchVendors().then(options => setVendors(options)).catch(err => console.log(err))
    }, [])

    function getPayload(): Payload {
        const formValues = form.getFieldsValue()
        //new payload
        const payload: Payload = {
            provincia: formValues.provincia,
            municipio: formValues.municipio,
            referencia_catastral: formValues.rc,
            via_publica: formValues.viapublica,
            origen: formValues.origen,
            cp: cp!,
            numero_paneles: '' + formValues.numeropaneles,
            tipo_cubierta: formValues.tipocubierta,
        }
        return payload
    }

    //generate payload
    function generarPayload(reportar: boolean) {
        if (customFeedback) {
            return
        }
        if (!reportar) {
            setLoading(true)
        }
        setMensaje("Generación del certificado en curso")

        let payload = getPayload()

        if (reportar) {
            Sentry.captureEvent({
                message: "Reporte de fallo",
                extra: {
                    payload: JSON.stringify(payload)
                }
            })
            return
        }
        axios({
            url: process.env.REACT_APP_API_URL + '/seguridadestructural',
            method: 'POST',
            data: payload,
            timeout: 90000,
            responseType: 'blob',
            auth: {
                username: localStorage.getItem("email") || "",
                password: localStorage.getItem("password") || "",
            }


        }).then((response) => {
            const href = URL.createObjectURL(response.data);
            const link = document.createElement('a');
            link.href = href;
            var contentDisposition = response.headers['content-disposition'];
            var filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
            setMensaje("Certificado generado correctamente")
            setLoading(false)
            setRegistrarDisabled(false)
        }).catch(async (error) => {
            setLoading(false)
            let responseObj = await error.response.data.text();
            setMensaje("Error al generar el reporte: " + JSON.parse(responseObj).error)
            console.log(responseObj)
        })
    }
    //payload type
    type Payload = {
        provincia: string,
        municipio: string,
        referencia_catastral: string,
        via_publica: string,
        origen: string
        cp: string
        numero_paneles: string,
        tipo_cubierta: string,
    }
 


    return (
        <Form
            {...layout}
            name="control-hooks"
            style={{ maxWidth: 1000 }}
            autoComplete="on"
            form={form}
            onFinish={() => generarPayload(false)}
        >
            <Show title="" headerProps={{ backIcon: false }} breadcrumb={<></>} headerButtons={<></>}>

                <Title level={3}>Datos iniciales</Title>
                <Title level={5}>Provincia</Title>
                <Form.Item name="provincia" label="" initialValue={provincia}>
                    <Input disabled ></Input>
                </Form.Item>
                <Title level={5}>Municipio</Title>
                <Form.Item name="municipio" label="" initialValue={municipio}>
                    <Input disabled ></Input>
                </Form.Item>
                <Title level={5}>Referencia catastral</Title>
                <Form.Item name="rc" label="" initialValue={rc}>
                    <Input disabled ></Input>
                </Form.Item>
            </Show>
            <Show title="" headerProps={{ backIcon: false }} breadcrumb={<></>} headerButtons={<></>}>
                <Title level={3}>Datos Generales</Title>
                <Title level={5}>Necesidad de ocupar la via pública</Title>
                <Form.Item name="viapublica" label="" initialValue={query.get("tipo")} rules={[{ required: true, message: "Campo requerido" }]}>
                    <Select showSearch placeholder="Seleccionar" >
                        <Option key="Sí">Sí</Option>
                        <Option key="No">No</Option>
                    </Select>
                </Form.Item>
                <Title level={5}>Número de paneles</Title>
                <Form.Item name="numeropaneles" label="" rules={[{ required: true, message: "Campo requerido" }]}>
                    <InputNumber min={1} max={9999}  />
                </Form.Item>
                <Title level={5}>Tipo de cubierta</Title>
                <Form.Item name="tipocubierta" label="" initialValue={query.get("tipo")} rules={[{ required: true, message: "Campo requerido" }]}>
                    <Select showSearch placeholder="Seleccionar" >
                        <Option key="Inclinada">Inclinada</Option>
                        <Option key="Plana">Plana</Option>
                    </Select>
                </Form.Item>
            </Show>
            <Show title="" headerProps={{ backIcon: false }} breadcrumb={<></>} headerButtons={<></>}>
                <Title level={5}>{"Estado: " + mensaje}</Title>
            </Show>
            <Show title="" headerProps={{ backIcon: false }} breadcrumb={<></>} headerButtons={<></>}>
                <Row>
                    <Col span={6}>
                        <Form.Item style={{ float: 'left' }} >
                            <Button id="generarButton" type="primary" htmlType="submit" loading={loading} style={{ marginRight: 10 }}>
                                Generar
                            </Button>
                        </Form.Item>
                    </Col>
                    <Col span={3}>

                    </Col>

                </Row>
            </Show>

        </Form>
    );
};


