
import { Show } from "@refinedev/antd";

import { Typography, Form, Input, Button, Select, Row, Col, Space, Checkbox, AutoComplete, Upload, UploadProps, message, InputNumber } from "antd";
import layout from "antd/es/layout";
import axios from "axios";
import { MasIcon, MenosIcon } from "components/icons";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { UploadOutlined } from "@ant-design/icons";
import { VendorOption, fetchVendors } from "API/functions";
import { isValidLegalEntityNif, isValidNaturalPersonNif } from "nif-dni-nie-cif-validation";
import Direccion from "components/direccion";


const { Title, Text } = Typography;
const { Option } = Select;
export const SegundaOcupacion2: React.FC = () => {
    const [form] = Form.useForm();
    //retrieve query params
    function useQuery() {
        const { search } = useLocation();

        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    let query = useQuery();
    let provincia = query.get("provincia")
    let municipio = query.get("municipio")
    let cp = query.get("cp")
    let rc = query.get("rc")
    //set number of cubiertas
    const [loading, setLoading] = useState<boolean>(false);
    const [registrarDisabled, setRegistrarDisabled] = useState<boolean>(false);
    const [usarDatosCliente, setUsarDatosCliente] = React.useState<boolean>(query.get("facturacionNIF") === null ? true : false)
    const [registrarloading, setregistrarloading] = useState<boolean>(false);
    const [mensaje, setMensaje] = React.useState<string>()

    const [vendors, setVendors] = React.useState<VendorOption[]>()

    //CustomValidation
    const [customFeedback, setCustomFeedback] = React.useState<boolean>(false)

    useEffect(() => {
        setMensaje("Ningun proceso en curso")
        fetchVendors().then(options => setVendors(options)).catch(err => console.log(err))
    }, [])

    function getPayload(): Payload {
        const formValues = form.getFieldsValue()
        //new payload
        const payload: Payload = {
            provincia: formValues.provincia,
            municipio: formValues.municipio,
            referencia_catastral: formValues.rc,
            cliente: {
                nombre: formValues.nombrecliente,
                apellidos: formValues.apellidoscliente,
                nif: formValues.nifcliente,
            },
            tipo_edificio: formValues.tipodeedificio,
            origen: formValues.origen,
            cp: cp!,
            fecha_reforma: '' + formValues.fechareforma,
        }
        return payload
    }

    //generate payload
    function generarPayload(reportar: boolean) {
        if (customFeedback) {
            return
        }
        if (!reportar) {
            setLoading(true)
        }
        setMensaje("Generación del certificado en curso")

        let payload = getPayload()

        if (reportar) {
            Sentry.captureEvent({
                message: "Reporte de fallo",
                extra: {
                    payload: JSON.stringify(payload)
                }
            })
            return
        }
        axios({
            url: process.env.REACT_APP_API_URL + '/segundaocupacion',
            method: 'POST',
            data: payload,
            timeout: 90000,
            responseType: 'blob',
            auth: {
                username: localStorage.getItem("email") || "",
                password: localStorage.getItem("password") || "",
            }


        }).then((response) => {
            const href = URL.createObjectURL(response.data);
            const link = document.createElement('a');
            link.href = href;
            var contentDisposition = response.headers['content-disposition'];
            var filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
            setMensaje("Certificado generado correctamente")
            setLoading(false)
            setRegistrarDisabled(false)
        }).catch(async (error) => {
            setLoading(false)
            let responseObj = await error.response.data.text();
            setMensaje("Error al generar el reporte: " + JSON.parse(responseObj).error)
            console.log(responseObj)
        })
    }
    //payload type
    type Payload = {
        provincia: string,
        municipio: string,
        referencia_catastral: string,
        cliente: cliente,
        tipo_edificio: string,
        origen: string
        cp: string
        fecha_reforma: string,
    }
    type cliente = {
        nombre: string,
        apellidos: string,
        nif: string,
    }
    type facturacion = {
        razon_social: string,
        nif: string,
        direccion: string,
        numero: string,
        cp: string,
        municipio: string,
        provincia: string,
    }


    return (
        <Form
            {...layout}
            name="control-hooks"
            style={{ maxWidth: 1000 }}
            autoComplete="on"
            form={form}
            onFinish={() => generarPayload(false)}
        >
            <Show title="" headerProps={{ backIcon: false }} breadcrumb={<></>} headerButtons={<></>}>

                <Title level={3}>Datos iniciales</Title>
                <Title level={5}>Provincia</Title>
                <Form.Item name="provincia" label="" initialValue={provincia}>
                    <Input disabled ></Input>
                </Form.Item>
                <Title level={5}>Municipio</Title>
                <Form.Item name="municipio" label="" initialValue={municipio}>
                    <Input disabled ></Input>
                </Form.Item>
                <Title level={5}>Referencia catastral</Title>
                <Form.Item name="rc" label="" initialValue={rc}>
                    <Input disabled ></Input>
                </Form.Item>
            </Show>
            <Show title="" headerProps={{ backIcon: false }} breadcrumb={<></>} headerButtons={<></>}>
                <Title level={3}>Datos del propietario</Title>
                <Row>
                    <Col span={12}>
                        <Form.Item name="nombrecliente" initialValue={query.get("nombrecliente")} label="Nombre" rules={[{ required: true, message: "Nombre requerido" }]}>
                            <Input ></Input>
                        </Form.Item></Col>
                    <Col span={12}>
                        <Form.Item name="apellidoscliente" initialValue={query.get("apellidoscliente")} label="Apellidos" rules={[{ required: true, message: "Apellidos requeridos" }]}>
                            <Input ></Input>
                        </Form.Item></Col>

                </Row>
                <Row>
                    <Col span={12}>
                        <Form.Item name="nifcliente" initialValue={query.get("nifcliente")} label="NIF" rules={[{
                                required: true, message: "NIF requerido", validator: (_, value) => {
                                    if (isValidNaturalPersonNif(value)) {
                                        return Promise.resolve();
                                    } else {
                                        return Promise.reject('NIF no válido');
                                    }
                                }
                            }]}>
                            <Input style={{ width: 200 }} ></Input>
                        </Form.Item></Col>
                    <Col span={12}>
                        <Form.Item name="origen" initialValue={query.get("origen")} label="Origen" rules={[{ required: true, message: "Origen requerido" }]}>
                            <AutoComplete
                                options={vendors}
                                placeholder="introduce primeras letras"
                                filterOption={(inputValue, option) =>
                                    option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                }
                            />
                        </Form.Item></Col>
                </Row>
            </Show>
            <Show title="" headerProps={{ backIcon: false }} breadcrumb={<></>} headerButtons={<></>}>
                <Title level={3}>Datos Generales</Title>
                <Title level={5}>Tipo de edificio</Title>
                <Form.Item name="tipodeedificio" label="" initialValue={query.get("tipo")} rules={[{ required: true, message: "Tipo de eficicio requerido" }]}>
                    <Select showSearch placeholder="Seleccionar tipo de edificio" >
                        <Option key="Unifamiliar">Unifamiliar</Option>
                        <Option key="Vivienda individual">Vivienda individual</Option>
                        <Option key="Local">Local</Option>
                    </Select>
                </Form.Item>
                <Title level={5}>Fecha de reforma</Title>
                <Form.Item name="fechareforma" label="" extra="Opcional. En caso de haber información en catastro se rellenará solo.">
                    <InputNumber min={0} max={9999}  />
                </Form.Item>
            </Show>
            <Show title="" headerProps={{ backIcon: false }} breadcrumb={<></>} headerButtons={<></>}>
                <Title level={5}>{"Estado: " + mensaje}</Title>
            </Show>
            <Show title="" headerProps={{ backIcon: false }} breadcrumb={<></>} headerButtons={<></>}>
                <Row>
                    <Col span={6}>
                        <Form.Item style={{ float: 'left' }} >
                            <Button id="generarButton" type="primary" htmlType="submit" loading={loading} style={{ marginRight: 10 }}>
                                Generar
                            </Button>
                        </Form.Item>
                    </Col>
                    <Col span={3}>

                    </Col>

                </Row>
            </Show>

        </Form>
    );
};


