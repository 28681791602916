import axios, { AxiosResponse } from "axios"

type VendorOption = {
    value: string,
}

async function fetchVendors(): Promise<VendorOption[]> {
    return axios.get(process.env.REACT_APP_API_URL + `/vendors`, {
        auth: {
            username: localStorage.getItem("email") || "",
            password: localStorage.getItem("password") || "",
        }
    })
        .then(response => {
            const vendors = response.data
            let options = vendors.map((vendor: string) => {
                return {
                    value: vendor
                }
            })
            return options
        })
}

async function fetchFacturas(fecha1:string,fecha2:string,nif:string,referencia_catastral:string,agrupar:boolean, numero_factura:string): Promise<AxiosResponse> {
    return axios.post(process.env.REACT_APP_API_URL + `/generarFacturas`,{
        desde: fecha1,
        hasta: fecha2,
        nif: nif,
        referencia_catastral: referencia_catastral,
        agrupar: agrupar,
        numero_factura: numero_factura
    }, {
        method: 'POST',
        responseType: 'blob', 
        auth: {
            username: localStorage.getItem("email") || "",
            password: localStorage.getItem("password") || "",
        },
    })
}

async function fetchCliente(abrev :string): Promise<AxiosResponse> {
    return axios.get(process.env.REACT_APP_API_URL + `/cliente/`+abrev+"/", {
        method: 'GET', 
    })
}
export type { VendorOption }
export { fetchVendors }
export { fetchFacturas }
export { fetchCliente }