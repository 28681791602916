import React, { useEffect } from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { Create, useForm, useSelect } from "@refinedev/antd";
import { Checkbox, Divider, Form, Input, InputNumber, Select, Typography } from "antd";
import TextArea from "antd/es/input/TextArea";
import { isValidLegalEntityNif, isValidNaturalPersonNif } from "nif-dni-nie-cif-validation";
import { fetchMunicipios, fetchProvincias } from "catastro/functions";
const { Title } = Typography;
export const ClienteCreate: React.FC<IResourceComponentsProps> = () => {
    const { formProps, saveButtonProps, queryResult,onFinish } = useForm();
    const [provincias, setProvincias] = React.useState<string[]>([])
    const [municipios, setMunicipios] = React.useState<string[]>([])
    const [saltarCEE, setSaltarCEE] = React.useState<boolean>(false)

    const { Option } = Select;
    const onProvinciaChange = (value: string) => {
        fetchMunicipios(value).then(municipios => setMunicipios(municipios))
    };
    useEffect(() => {
        fetchProvincias().then(provincias => setProvincias(provincias))
    }, [])
    const handleOnFinish = async (values: any) => {
        onFinish({
          ...values,
          codigo_postal : ''+values.codigo_postal,
          numero : ''+values.numero,
          notas : values.notas || '',
          precio_cee : ''+(values.precio_cee || ''),
          saltar_tasa: saltarCEE,
        });
      };
      const changeSaltarCEE = (e: any) => {
        setSaltarCEE(e.target.checked)
    }
    return (
        <Create saveButtonProps={saveButtonProps}>
                         <Title level={4}>Datos del cliente:</Title>

            <Form {...formProps} layout="vertical"
            onFinish={handleOnFinish}
            >
                <Form.Item
                    label="Razón Social"
                    name={["razon_social"]}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="NIF/CIF"
                    name={["nif"]}
                    rules={[{
                        required: true, message: "NIF/CIF requerido", validator: (_, value) => {
                            if (isValidNaturalPersonNif(value) || isValidLegalEntityNif(value)) {
                                return Promise.resolve();
                            } else {
                                return Promise.reject('NIF/CIF no válido');
                            }
                        }
                    }]}>
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Direccion"
                    name={["direccion"]}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Número"
                    name={"numero"}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <InputNumber ></InputNumber>
                </Form.Item>
                <Form.Item
                    label="Codigo Postal"
                    name={"codigo_postal"}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <InputNumber ></InputNumber>
                </Form.Item>
                <Form.Item
                    label="Provincia"
                    name={["provincia"]}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select showSearch
                        placeholder="Seleccionar provincia"
                        onChange={onProvinciaChange}
                        allowClear
                    >
                        {provincias.map((provincia) => (
                            <Option key={provincia} value={provincia}>{provincia}</Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Municipio"
                    name={["ciudad"]}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select showSearch
                        placeholder="Seleccionar municipio"
                        allowClear
                    >
                        {municipios.map((municipio) => (
                            <Option key={municipio} value={municipio}>{municipio}</Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Pais"
                    name={["pais"]}
                    initialValue={"España"}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input disabled />
                </Form.Item>
                <Form.Item
                    label="Email"
                    name={["email"]}
                    rules={[{ required: true, message: "Email requerido", type: "email" }]}>
                    <Input type="email" />
                </Form.Item>

                <Form.Item
                    label="Teléfono"
                    name={"telefono"}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Abrev."
                    name={"enlaceauto"}
                    extra="Abreviatura para enlace automático"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                
                <Divider />
            <Title level={4}>Opciones de facturación:</Title>
            <Form.Item
                    label="Precio CEE"
                    extra="(dejar en blanco si no se quiere aplicar un precio especial)"
                    name={["precio_cee"]}
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                   <InputNumber min={0} max={10000} decimalSeparator={"."} addonAfter="€" />
                </Form.Item>
                <Form.Item
                    label=""
                    name={"saltar_tasa"}
                    extra="La tasa de registro siempre sera 0.00€ para este cliente"
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <Checkbox onChange={changeSaltarCEE} checked={saltarCEE}> Saltar tasa de registro CEE</Checkbox>
                    </Form.Item>
                    <Divider />
            <Title level={4}>Otros:</Title>
                    <Form.Item
                    label="Notas"
                    name={["notas"]}
                >
                    <TextArea />
                </Form.Item>
            </Form>
        </Create>
    );
};
