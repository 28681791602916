import React from "react";
import { IResourceComponentsProps, BaseRecord } from "@refinedev/core";
import {
    useTable,
    List,
    EditButton,
    ShowButton,
    DateField,
    DeleteButton,
} from "@refinedev/antd";
import { Table, Space, Form, Input, Button } from "antd";

export const RegistroList: React.FC<IResourceComponentsProps> = () => {
    const { tableProps, searchFormProps } = useTable({
        syncWithLocation: true,
        pagination: {
            pageSize: 10,
        },
        onSearch: (values: any) => {
            return [
                {
                    field: "search",
                    operator: "contains",
                    value: values.search,
                },
            ];
        },
    });

    return (
        <List>
            <Form {...searchFormProps} layout="inline" style={{ marginBottom: 15 }}>
                <Form.Item name="search">
                    <Input placeholder=" Introducir busqueda" />
                </Form.Item>
                <Button type="primary" onClick={searchFormProps.form?.submit}>Buscar</Button>
            </Form>
            <Table {...tableProps} rowKey="id" pagination={{
                ...tableProps.pagination,
                position: ["bottomCenter"],
                size: "default",
                showQuickJumper: {
                    goButton: <Button type="primary" style={{ marginLeft: 10 }}>Ir</Button>,
                },
            }}>
                 <Table.Column
                    title="Acciones"
                    dataIndex="actions"
                    render={(_, record: BaseRecord) => (
                        <Space>
                             <ShowButton
                                hideText
                                size="small"
                                recordItemId={record._id}
                            />
                            <EditButton
                                hideText
                                size="small"
                                recordItemId={record._id}
                            />
                             <DeleteButton
                                hideText
                                size="small"
                                recordItemId={record._id}
                            />
                        </Space>
                    )}
                />
                <Table.Column
                    dataIndex="referencia_catastral"
                    title="Referencia Catastral"
                />
                <Table.Column dataIndex="nif_cliente" title="NIF" />
                <Table.Column
                    dataIndex="nombre_cliente"
                    title="Razón Social"
                />
                <Table.Column
                    dataIndex="ciudad_cliente"
                    title="Ciudad "
                />
                <Table.Column
                    dataIndex="codigopostal_cliente"
                    title="CP"
                />
                <Table.Column
                    dataIndex="direccion_cliente"
                    title="Direccion "
                />
                <Table.Column
                    dataIndex={["tasa_registro"]}
                    title="Tasa Registro"
                />
                <Table.Column
                    dataIndex={["timestamp"]}
                    title="Fecha"                                //2023-12-26T09:18:47.639Z
                    render={(value: any) => <DateField value={value} format={"DD/MM/YYYY"} />}
                />
                <Table.Column dataIndex="tipo_edificio" title="Tipo Edificio" />
                <Table.Column dataIndex="tipo_factura" title="Tipo Factura" />
            </Table>
        </List>
    );
};
