import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { Create, useForm, useSelect } from "@refinedev/antd";
import { Form, Input, Select, DatePicker, InputNumber } from "antd";
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import locale from 'antd/es/date-picker/locale/es_ES';
import { isValidLegalEntityNif, isValidNaturalPersonNif } from "nif-dni-nie-cif-validation";

export const RegistroCreate: React.FC<IResourceComponentsProps> = () => {
    const { formProps, saveButtonProps, queryResult,onFinish } = useForm();
    dayjs.locale('es');
    const { Option } = Select;
    const handleOnFinish = async (values: any) => {
        onFinish({
          ...values,
          tasa_registro: ''+values.tasa_registro,
          codigopostal_cliente : ''+values.codigopostal_cliente,
          precio : ''+values.precio,
        });
      };
    return (
        <Create saveButtonProps={saveButtonProps} title="Crear registro manual">
            <Form {...formProps} layout="vertical"
            onFinish={handleOnFinish} >
            <Form.Item
                    label="Referencia Catastral"
                    name={["referencia_catastral"]}
                    rules={[{ required: true, message: "Referencia catastral requerida" }, { len: 20, message: "La referencia catastral debe tener 20 caracteres" }]}>
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Tipo Edificio"
                    name={["tipo_edificio"]}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select showSearch placeholder="Seleccionar tipo de edificio">
                            <Option key="Unifamiliar">Unifamiliar</Option>
                            {/* <Option key="Bloque de viviendas">Bloque de viviendas</Option> */}
                            <Option key="Vivienda individual">Vivienda individual</Option>
                            <Option key="Local">Local</Option>
                        </Select>
                </Form.Item>
                <Form.Item
                    label="Ciudad"
                    name={["ciudad_cliente"]}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Codigo postal"
                    name={"codigopostal_cliente"}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <InputNumber ></InputNumber>
                </Form.Item>
                <Form.Item
                    label="Dirección"
                    name={["direccion_cliente"]}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="NIF"
                    name={["nif_cliente"]}
                    rules={[{
                        required: true, message: "NIF/CIF requerido", validator: (_, value) => {
                            if (isValidNaturalPersonNif(value) || isValidLegalEntityNif(value)) {
                                return Promise.resolve();
                            } else {
                                return Promise.reject('NIF/CIF no válido');
                            }
                        }
                    }]}>
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Razón social"
                    name={["nombre_cliente"]}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Precio "
                    extra="(dejar en blanco si no se quiere aplicar un precio especial)"
                    name={["precio"]}
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                   <InputNumber min={0} max={10000} decimalSeparator={"."} addonAfter="€" />
                </Form.Item>
                <Form.Item
                    label="Tasa Registro"
                    name={["tasa_registro"]}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <InputNumber min={0} max={10000} decimalSeparator={"."} addonAfter="€" />
                </Form.Item>
                <Form.Item
                    label="Fecha de registro"
                    name={["timestamp"]}
                    initialValue={dayjs()}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <DatePicker format={"DD-MM-YYYY"}/>
                </Form.Item>
                <Form.Item
                    label="Tipo Factura"
                    name={["tipo_factura"]}
                    initialValue={"CEE"}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input disabled/>
                </Form.Item>
            </Form>
        </Create>
    );
};
