
import { Show } from "@refinedev/antd";

import { Typography, Form, Row, Button, Col, Input, Checkbox, Divider, Alert, Card, InputNumber } from "antd";
import layout from "antd/es/layout";
import React, { useEffect } from "react";
import { DatePicker } from 'antd';
import { fetchFacturas } from "API/functions";
import { isValidLegalEntityNif, isValidNaturalPersonNif } from "nif-dni-nie-cif-validation";
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import locale from 'antd/es/date-picker/locale/es_ES';

const { RangePicker } = DatePicker;


const { Title } = Typography;
export const Facturas: React.FC = () => {
    const [form] = Form.useForm();
    const [agrupar, setAgrupar] = React.useState<boolean>(true)
    const [mensaje, setMensaje] = React.useState<string>("Ningún proceso en curso")
    const [loading, setLoading] = React.useState<boolean>(false)
    useEffect(() => {

    }, [])
    dayjs.locale('es');

    const verificarDatos = () => {
        const formValues = form.getFieldsValue()
        setLoading(true)
        setMensaje("Generando facturas...")
        fetchFacturas(formValues.periodo[0].format("DD-MM-YYYY"), formValues.periodo[1].format("DD-MM-YYYY"), formValues.nif, formValues.rc, agrupar, ''+formValues.numerofactura)
            .then((res) => {
                setMensaje("Descargando facturas...")
                setLoading(false)
                const href = URL.createObjectURL(res.data);
                // create "a" HTML element with href to file & click
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', 'facturas.zip'); //or any other extension
                document.body.appendChild(link);
                link.click();
                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
                setMensaje("Facturas descargadas correctamente")
            })
            .catch(async (res) => {
                setLoading(false)
                let responseObj = await res.response.data.text();
                setMensaje("(error) " + JSON.parse(responseObj).error)
                console.log(res)
            })
    };

    const changeAgrupar = (e: any) => {
        setAgrupar(e.target.checked)
    }


    return (
        <Form
            {...layout}
            form={form}
            name="control-hooks"
            style={{ maxWidth: 1000 }}
            autoComplete="on"
            onFinish={verificarDatos}
        >
            <Show
                title="Generar facturas (Beta)"
                headerProps={{
                    backIcon: false,
                }}
                headerButtons={<></>}
            >
                <Title level={5}>Filtrar por fecha:</Title>
                <Form.Item name="periodo" label="" initialValue={[dayjs().startOf('month'), dayjs().endOf('month')]} rules={[{ required: true, message: "Periodo requerido" }]}>
                    <RangePicker format={"DD-MM-YYYY"} locale={locale} />
                </Form.Item>
                <Title level={5}>Filtrar por CIF/NIF:</Title>
                <Form.Item name="nif" label="" rules={[
                    {
                        required: false,
                        message: '',
                    },
                    () => ({
                        validator(_, value) {
                            if (isValidNaturalPersonNif(value) || isValidLegalEntityNif(value) || value === undefined || value === "") {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('NIF/CIF no correcto'));
                        },
                    }),
                ]}>
                    <Input style={{ width: 265 }} />
                </Form.Item>
                <Title level={5}>Filtrar por referencia catastral:</Title>
                <Form.Item name="rc" label="" rules={[{ required: false, message: "Referencia catastral requerida" }, { len: 20, message: "La referencia catastral debe tener 20 caracteres" }]}>
                    <Input style={{ width: 265 }} />
                </Form.Item>
                <Title level={5}>Número de inicio de facturas (o factura a rectificar):</Title>
                <Form.Item name="numerofactura" label="" rules={[
                    {
                        required: true,
                        message: '',
                    },
                    () => ({
                        validator(_, value) {
                            const formValues = form.getFieldsValue()
                            //Este caso cubre que si no hay referencia catastral el numero de factura debe ser un numero
                            if ((formValues.rc === undefined || formValues.rc === "") && !isNaN(value)) {
                                return Promise.resolve();
                            }
                            //Este caso cubre que si hay referencia catastral el numero de factura puede o no, ser un numero
                            if (formValues.rc !== undefined && formValues.rc !== "" && value !== undefined && value !== ""){
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('Si no se ha definido referencia catastral, el número de factura debe ser un número. Si se ha definido referencia catastral, el número de factura puede ser una cadena de texto o un número. Por ejemplo 101R o 101'));
                        },
                    }),
                ]}>
                    <Input style={{ width: 100 }} />
                </Form.Item>
                <Form.Item name="agrupar" label="">
                    <Checkbox checked={agrupar} onChange={changeAgrupar}>Agrupar facturas de un mismo NIF/CIF</Checkbox>
                </Form.Item>
            </Show>
            <Show title="" headerProps={{ backIcon: false }} breadcrumb={<></>} headerButtons={<></>}>
                <Title level={5}>{"Estado: " + mensaje}</Title>
                <Divider />
                <Row>
                    <Col span={12}>
                        <Form.Item  >
                            <Button loading={loading} id="generarButton" type="primary" htmlType="submit" >
                                Generar
                            </Button>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item style={{ float: "right" }} >
                            <Button id="facturaManual" type="default" href="/crearfactura">
                                Factura manual
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Show>
            <Show title="" headerProps={{ backIcon: false }} breadcrumb={<></>} headerButtons={<></>}>
                <Card title="Reglas de facturación" >
                    <p>- El precio por defecto para los CEE son 50€. Es posible modificar el precio de un CEE por cliente, o incluso por registro actualizando el valor en la base de datos. Al igual que es posible definir si se le cobra, o no, la tasa de registro a un cliente. </p>
                </Card>
            </Show>
        </Form>

    );
};
