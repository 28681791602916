
import { Show } from "@refinedev/antd";

import { Typography, Form, Input, Button, Select, Row, Col, Space, Checkbox, AutoComplete, Upload, UploadProps, message, DatePicker, ConfigProvider } from "antd";
import layout from "antd/es/layout";
import axios from "axios";
import { MasIcon, MenosIcon } from "components/icons";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { UploadOutlined } from "@ant-design/icons";
import { VendorOption, fetchVendors } from "API/functions";
import { isValidLegalEntityNif, isValidNaturalPersonNif } from "nif-dni-nie-cif-validation";
import Direccion from "components/direccion";
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import locale from 'antd/locale/es_ES';
const { RangePicker } = DatePicker;

const { Title, Text } = Typography;
const { Option } = Select;
export const NuevoCertificado2: React.FC = () => {
    const [form] = Form.useForm();
    //retrieve query params
    function useQuery() {
        const { search } = useLocation();

        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    let query = useQuery();
    let provincia = query.get("provincia")
    let municipio = query.get("municipio")
    let cp = query.get("cp")
    let rc = query.get("rc")
    //set number of cubiertas
    const [cubiertas, setCubiertas] = React.useState<number>()
    const [muroFacha, setMuroFacha] = React.useState<number>()
    const [muroMedia, setMuroMedia] = React.useState<number>()
    const [huecos, setHuecos] = React.useState<number>()
    const [loading, setLoading] = useState<boolean>(false);
    const [registrarDisabled, setRegistrarDisabled] = useState<boolean>(false);
    const [usarDatosCliente, setUsarDatosCliente] = React.useState<boolean>(query.get("facturacionNIF") === null ? true : false)
    const [registrarloading, setregistrarloading] = useState<boolean>(false);
    const [mensaje, setMensaje] = React.useState<string>()
    const [previo, setPrevio] = useState<boolean>(false);

    const [vendors, setVendors] = React.useState<VendorOption[]>()

    dayjs.locale('es');
    //CustomValidation
    const [customHelp, setCustomHelp] = React.useState<string>("")
    const [customStatus, setCustomStatus] = React.useState<any>("")
    const [customFeedback, setCustomFeedback] = React.useState<boolean>(false)

    useEffect(() => {
        setCubiertas(1)
        setMuroFacha(1)
        setMuroMedia(1)
        setHuecos(1)
        setMensaje("Ningun proceso en curso")
        fetchVendors().then(options => setVendors(options)).catch(err => console.log(err))
    }, [])
    const openLinkInNewTab = (url: string | URL | undefined) => {
        const newTab = window.open(url, '_blank', 'noopener,noreferrer');
        if (newTab) newTab.opener = null;
    }
    //increase number of cubiertas
    const addCubierta = () => {
        if (cubiertas === undefined) {
            setCubiertas(1)
        }
        else {
            setCubiertas(cubiertas + 1)
        }
    }
    //decrease number of cubiertas
    const removeCubierta = () => {
        if (cubiertas === undefined) {
            setCubiertas(0)
        }
        else {
            if (cubiertas === 0) {
                return
            }
            else {
                setCubiertas(cubiertas - 1)
            }
        }
    }
    //increase number of muros
    const addMuro = () => {
        if (muroFacha === undefined) {
            setMuroFacha(1)
        }
        else {
            setMuroFacha(muroFacha + 1)
        }
    }
    //decrease number of muros
    const removeMuro = () => {
        if (muroFacha === undefined) {
            setMuroFacha(0)
        }
        else {
            if (muroFacha === 0 || muroFacha === 1) {
                return
            }
            else {
                setMuroFacha(muroFacha - 1)
            }
        }
    }
    //increase number of muros
    const addMuroMedia = () => {
        if (muroMedia === undefined) {
            setMuroMedia(1)
        }
        else {
            setMuroMedia(muroMedia + 1)
        }
    }
    //decrease number of muros
    const removeMuroMedia = () => {
        if (muroMedia === undefined) {
            setMuroMedia(0)
        }
        else {
            if (muroMedia === 0) {
                return
            }
            else {
                setMuroMedia(muroMedia - 1)
            }
        }
    }
    //increase number of huecos
    const addHueco = () => {
        if (huecos === undefined) {
            setHuecos(1)
        }
        else {
            setHuecos(huecos + 1)
        }
    }
    //decrease number of huecos
    const removeHueco = () => {
        if (huecos === undefined) {
            setHuecos(0)
        }
        else {
            if (huecos === 0) {
                return
            }
            else {
                setHuecos(huecos - 1)
            }
        }
    }

    //validate medidasMejora
    const medidasValidation = () => {
        if (!(form.getFieldValue("medidasmejora")?.length === 3)) {
            setCustomStatus("error")
            setCustomHelp("Debe seleccionar 3 medidas de mejora")
            setCustomFeedback(true)
        } else {
            setCustomStatus("")
            setCustomHelp("")
            setCustomFeedback(false)

        }
    }

    //registrar certificado
    const registrarCertificado = () => {
        setregistrarloading(true)
        axios({
            url: "http://localhost:5768" + '/registrar',
            method: 'POST',
            data: getPayload(),
            timeout: 90000,
            responseType: 'blob',
        }).then((response) => {
            setMensaje("Registro lanzado correctamente, continua en el navegador")
            setregistrarloading(false)
        }).catch(async (error) => {
            setregistrarloading(false)
            let responseObj = await error.response.data.text();
            setMensaje("Error al lanzar el registro: " + JSON.parse(responseObj).error)
            console.log(responseObj)
        })

    }
    const changeUsarDatosCliente = (e: any) => {
        setUsarDatosCliente(e.target.checked)
    }

    const changePrevio = (e: any) => {
        setPrevio(e.target.checked)
    }

    function getPayload(): Payload {
        const formValues = form.getFieldsValue()
        //create envolventes array of type envolvente
        const envolventes: envolvente[] = []
        for (let i = 0; i < cubiertas!; i++) {
            const cubierta = {
                tipo: "Cubierta",
                nombre: formValues["nombrecubierta" + i],
                l: +formValues["longitudcubierta" + i],
                a: +formValues["anchuracubierta" + i],
                plana_o_inclinada: formValues["tipocubierta" + i],
                fachada_o_medianera: "",
                orientacion: "",
                cerramiento_asociado: "",
                tipo_vidrio: "",
                tipo_marco: ""
            }
            envolventes.push(cubierta)
        }
        for (let i = 0; i < muroFacha!; i++) {
            const muro = {
                tipo: "Muro",
                nombre: formValues["nombremurofacha" + i],
                l: +formValues["longitudmurofacha" + i],
                a: +formValues["anchuramurofacha" + i],
                plana_o_inclinada: "",
                fachada_o_medianera: "Fachada",
                orientacion: formValues["orientacionmurofacha" + i],
                cerramiento_asociado: "",
                tipo_vidrio: "",
                tipo_marco: ""
            }
            envolventes.push(muro)
        }
        for (let i = 0; i < muroMedia!; i++) {
            const muro = {
                tipo: "Muro",
                nombre: formValues["nombremuromedia" + i],
                l: +formValues["longitudmuromedia" + i],
                a: +formValues["anchuramuromedia" + i],
                plana_o_inclinada: "",
                fachada_o_medianera: "Medianera",
                orientacion: "",
                cerramiento_asociado: "",
                tipo_vidrio: "",
                tipo_marco: ""
            }
            envolventes.push(muro)
        }
        for (let i = 0; i < huecos!; i++) {
            const hueco = {
                tipo: "Hueco",
                nombre: formValues["nombrehueco" + i],
                l: +formValues["longitudhueco" + i],
                a: +formValues["anchurahueco" + i],
                plana_o_inclinada: "",
                fachada_o_medianera: "",
                orientacion: "",
                cerramiento_asociado: formValues["asociadohueco" + i],
                tipo_vidrio: formValues["tipovidriohueco" + i],
                tipo_marco: formValues["tipomarcohueco" + i]
            }
            envolventes.push(hueco)
        }
        //new payload
        const payload: Payload = {
            provincia: formValues.provincia,
            municipio: formValues.municipio,
            referencia_catastral: formValues.rc,
            cliente: {
                nombre: formValues.nombrecliente,
                apellidos: formValues.apellidoscliente,
                nif: formValues.nifcliente,
            },
            tipo_edificio: formValues.tipodeedificio,
            envolventes: envolventes,
            puente_termico: formValues.puentetermico,
            medidas_mejora: formValues.medidasmejora,
            tipo_instalacion: formValues.tipodeinstalacion,
            tipo_combustible: formValues.tipodecombustible,
            con_acumulacion: formValues.conacumulacion,
            con_contribuciones: formValues.concontribuciones,
            previoyposterior: formValues.previoyposterior,
            fechaVisita: formValues.fechavisita?.format("DD-MM-YYYY") || "",
            origen: formValues.origen,
            cp: cp!,
            usar_datos_cliente: usarDatosCliente,
            facturacion: {
                razon_social: formValues.razonsocialfacturacion,
                nif: formValues.niffacturacion,
                direccion: formValues.callefacturacion,
                municipio: formValues.municipiofacturacion,
                provincia: formValues.provinciafacturacion,
                cp: '' + formValues.codigopostalfacturacion,
                numero: '' + formValues.numerofacturacion,
            },
        }
        return payload
    }

    //generate payload
    function generarPayload(reportar: boolean) {
        if (customFeedback) {
            return
        }
        if (!reportar) {
            setLoading(true)
        }
        setMensaje("Generación del certificado en curso")

        let payload = getPayload()

        if (reportar) {
            Sentry.captureEvent({
                message: "Reporte de fallo",
                extra: {
                    payload: JSON.stringify(payload)
                }
            })
            return
        }
        axios({
            url: process.env.REACT_APP_API_URL + '/generar',
            method: 'POST',
            data: payload,
            timeout: 300000,
            responseType: 'blob',
            auth: {
                username: localStorage.getItem("email") || "",
                password: localStorage.getItem("password") || "",
            }


        }).then((response) => {
            // create file link in browser's memory
            //const href = URL.createObjectURL(response.data);

            // create "a" HTML element with href to file & click
            //const link = document.createElement('a');
            //link.href = href;
            //link.setAttribute('download', payload.referencia_catastral + '.zip'); //or any other extension
            //document.body.appendChild(link);
            //link.click();

            // clean up "a" element & remove ObjectURL
            //document.body.removeChild(link);
            //URL.revokeObjectURL(href);
            setMensaje("Reporte generado correctamente")
            setLoading(false)
            setRegistrarDisabled(false)
        }).catch(async (error) => {
            setLoading(false)
            let responseObj = await error.response.data.text();
            setMensaje("Error al generar el reporte: " + JSON.parse(responseObj).error)
            console.log(responseObj)
        })
    }
    //payload type
    type Payload = {
        provincia: string,
        municipio: string,
        referencia_catastral: string,
        cliente: cliente,
        tipo_edificio: string,
        envolventes: envolvente[]
        puente_termico: string[]
        tipo_instalacion: string
        medidas_mejora: string[]
        tipo_combustible: string
        con_acumulacion: boolean
        con_contribuciones: boolean
        origen: string
        cp: string
        usar_datos_cliente: boolean
        facturacion: facturacion
        previoyposterior: boolean
        fechaVisita: string
    }
    type cliente = {
        nombre: string,
        apellidos: string,
        nif: string,
    }
    type facturacion = {
        razon_social: string,
        nif: string,
        direccion: string,
        numero: string,
        cp: string,
        municipio: string,
        provincia: string,
    }
    interface envolvente {
        tipo: string,
        nombre: string,
        l: number,
        a: number,
        plana_o_inclinada: string
        fachada_o_medianera: string
        orientacion: string
        cerramiento_asociado: string
        tipo_vidrio: string
        tipo_marco: string
    }

    const defaultCheckedList = ['Pilar integrado en fachada', 'Contorno de hueco', 'Caja de persiana', 'Encuentro de fachada con forjado', 'Encuentro de fachada con cubierta', 'Encuentro de fachada con solera'];
    const defaultCheckedList2 = ['Adición de aislamiento térmico en fachada por el exterior', 'Sustitución de ventanas', 'Sustitución de calderas de combustión por otras de mayor eficiencia energética'];

    const props: UploadProps = {
        name: 'file',
        action: process.env.REACT_APP_API_URL + '/delegacion/' + rc + "/",
        headers: {
            Authorization: "Basic " + btoa(localStorage.getItem("email") + ":" + localStorage.getItem("password")),
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };

    return (
        <Form
            {...layout}
            name="control-hooks"
            style={{ maxWidth: 1000 }}
            autoComplete="on"
            form={form}
            onFinish={() => generarPayload(false)}
        >
            <Show title="" headerProps={{ backIcon: false }} breadcrumb={<></>} headerButtons={<></>}>

                <Title level={3}>Datos iniciales</Title>
                <Title level={5}>Provincia</Title>
                <Form.Item name="provincia" label="" initialValue={provincia}>
                    <Input disabled ></Input>
                </Form.Item>
                <Title level={5}>Municipio</Title>
                <Form.Item name="municipio" label="" initialValue={municipio}>
                    <Input disabled ></Input>
                </Form.Item>
                <Title level={5}>Referencia catastral</Title>
                <Form.Item name="rc" label="" initialValue={rc}>
                    <Input disabled ></Input>
                </Form.Item>
            </Show>
            <Show title="" headerProps={{ backIcon: false }} breadcrumb={<></>} headerButtons={<></>}>
                <Title level={3}>Datos del propietario</Title>
                <Row>
                    <Col span={12}>
                        <Form.Item name="nombrecliente" initialValue={query.get("nombrecliente")} label="Nombre" rules={[{ required: true, message: "Nombre requerido" }]}>
                            <Input ></Input>
                        </Form.Item></Col>
                    <Col span={12}>
                        <Form.Item name="apellidoscliente" initialValue={query.get("apellidoscliente")} label="Apellidos" rules={[{ required: true, message: "Apellidos requeridos" }]}>
                            <Input ></Input>
                        </Form.Item></Col>

                </Row>
                <Row>
                    <Col span={12}>
                        <Form.Item name="nifcliente" initialValue={query.get("nifcliente")} label="NIF" rules={[{
                                required: true, message: "NIF requerido", validator: (_, value) => {
                                    if (isValidNaturalPersonNif(value)) {
                                        return Promise.resolve();
                                    } else {
                                        return Promise.reject('NIF no válido');
                                    }
                                }
                            }]}>
                            <Input style={{ width: 200 }} ></Input>
                        </Form.Item></Col>
                    <Col span={12}>
                        <Form.Item name="origen" initialValue={query.get("origen")} label="Origen" rules={[{ required: true, message: "Origen requerido" }]}>
                            <AutoComplete
                                options={vendors}
                                placeholder="introduce primeras letras"
                                filterOption={(inputValue, option) =>
                                    option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                }
                            />
                        </Form.Item></Col>
                    {query.get("desdeSolicitud") !== "1"
                        ? <Form.Item name="delegacion" initialValue="" label="Archivo delegación">
                            <Upload {...props}>
                                <Button icon={<UploadOutlined />}>Seleccionar archivo</Button>
                            </Upload>
                        </Form.Item>
                        : <p>☑︎ Archivo de delegación generado desde la solicitud</p>
                    }

                </Row>
            </Show>
            <Show title="" headerProps={{ backIcon: false }} breadcrumb={<></>} headerButtons={<></>}>
                    <Title level={3}>Datos de facturación</Title>
                    <p>Los datos de facturación solo tienen efecto a nivel interno durante la creación de la factura de certinae, no afectan a la generación ni el registro de los certificados.
                    </p>
                    <Row>
                        <Checkbox checked={usarDatosCliente} onChange={changeUsarDatosCliente} style={{ marginBottom: 15 }}>Usar datos del propietario.</Checkbox>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item name="razonsocialfacturacion" initialValue={query.get("facturacionRazon")} label="Razon social" rules={[{ required: !usarDatosCliente, message: "Razon social requerida" }]}>
                                <Input disabled={usarDatosCliente}></Input>
                            </Form.Item></Col>
                        <Col span={12}>
                            <Form.Item name="niffacturacion" initialValue={query.get("facturacionNIF")} label="NIF/CIF" style={{ marginLeft: 15 }} rules={[
                                {
                                    required: !usarDatosCliente,
                                    message: '',
                                },
                                () => ({
                                    validator(_, value) {
                                        if (usarDatosCliente) {
                                            return Promise.resolve();
                                        }
                                        if (isValidNaturalPersonNif(value) || isValidLegalEntityNif(value)) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('NIF/CIF no correcto'));
                                    },
                                }),
                            ]}>
                                <Input disabled={usarDatosCliente} style={{ width: 200 }}></Input>
                            </Form.Item></Col>

                    </Row>
                    <Direccion tipo="facturacion" required={usarDatosCliente} />
                </Show>
            <Show title="" headerProps={{ backIcon: false }} breadcrumb={<></>} headerButtons={<></>}>
                <Title level={3}>Datos Generales</Title>
                <Title level={5}>Tipo de edificio</Title>
                <Form.Item name="tipodeedificio" label="" initialValue={query.get("tipo")} rules={[{ required: true, message: "Tipo de eficicio requerido" }]}>
                    <Select showSearch placeholder="Seleccionar tipo de edificio" >
                        <Option key="Unifamiliar">Unifamiliar</Option>
                        <Option key="Bloque de viviendas">Bloque de viviendas</Option>
                        <Option key="Vivienda individual">Vivienda individual</Option>
                        <Option key="Local">Local</Option>
                    </Select>
                </Form.Item>
            </Show>
            <Show title="" headerProps={{ backIcon: false }} breadcrumb={<></>} headerButtons={<></>}>
                <Title level={3}>Envolvente Termica</Title>

                {/* CUBIERTAS */}
                <Row>
                    <Col span={6}>
                        <Title level={5}>Cubierta</Title>
                    </Col>
                    <Col span={18} >
                        <Space wrap style={{ float: 'right' }}>

                            <Button type="primary" size="small" onClick={removeCubierta} >
                                <MenosIcon />
                            </Button>
                            <Button type="primary" size="small" onClick={addCubierta}>
                                <MasIcon />
                            </Button>
                        </Space>
                    </Col>

                </Row>
                {Array.from(Array(cubiertas), (e, i) => {
                    return <>
                        <Row>
                            <Col span={6}>
                                <Form.Item initialValue={"C" + (i + 1)} name={"nombrecubierta" + i} label="Nombre" rules={[{ required: true, message: "Tipo de eficicio requerido" }]}>
                                    <Input ></Input>
                                </Form.Item></Col>
                            <Col span={6}>
                                <Form.Item name={"longitudcubierta" + i} label="Longitud" rules={[{ required: true, pattern: /^[+]?\d+([.]\d+)?$/, message: "Un numero positivo es requerido" }]}>
                                    <Input type="number"></Input>
                                </Form.Item></Col>
                            <Col span={6}>
                                <Form.Item name={"anchuracubierta" + i} label="Anchura" rules={[{ required: true, pattern: /^[+]?\d+([.]\d+)?$/, message: "Un numero positivo es requerido" }]}>
                                    <Input type="number"></Input>
                                </Form.Item></Col>
                            <Col span={6}>
                                <Form.Item name={"tipocubierta" + i} initialValue="Plana" label="Tipo" rules={[{ required: true, message: "Tipo de eficicio requerido" }]}>
                                    <Select showSearch placeholder=""  >
                                        <Option key="plana">Plana</Option>
                                        <Option key="inclinada">Inclinada</Option>
                                    </Select>
                                </Form.Item></Col>
                        </Row>
                    </>
                })}

                {/* MUROFACHADA */}

                <Row>
                    <Col span={6}>
                        <Title level={5}>Muro (Fachada)</Title>
                    </Col>
                    <Col span={18} >
                        <Space wrap style={{ float: 'right' }}>

                            <Button type="primary" size="small" onClick={removeMuro} >
                                <MenosIcon />
                            </Button>
                            <Button type="primary" size="small" onClick={addMuro}>
                                <MasIcon />
                            </Button>
                        </Space>
                    </Col>

                </Row>
                {Array.from(Array(muroFacha), (e, i) => {
                    return <>
                        <Row>
                            <Col span={6}>
                                <Form.Item initialValue={"F" + (i + 1)} name={"nombremurofacha" + i} label="Nombre" rules={[{ required: true }]}>
                                    <Input ></Input>
                                </Form.Item></Col>
                            <Col span={6}>
                                <Form.Item name={"longitudmurofacha" + i} label="Longitud" rules={[{ required: true, pattern: /^[+]?\d+([.]\d+)?$/, message: "Un numero positivo es requerido" }]}>
                                    <Input type="number"></Input>
                                </Form.Item></Col>
                            <Col span={6}>
                                <Form.Item name={"anchuramurofacha" + i} label="Anchura" rules={[{ required: true, pattern: /^[+]?\d+([.]\d+)?$/, message: "Un numero positivo es requerido" }]}>
                                    <Input type="number"></Input>
                                </Form.Item></Col>
                            <Col span={6}>
                                <Form.Item name={"orientacionmurofacha" + i} initialValue="N" label="Orientacion" rules={[{ required: true }]}>
                                    <Select showSearch placeholder=""  >
                                        <Option value="N" key={"N" + i}>N</Option>
                                        <Option value="S" key={"S" + i}>S</Option>
                                        <Option value="E" key={"E" + i}>E</Option>
                                        <Option value="O" key={"O" + i}>O</Option>
                                        <Option value="NE" key={"NE" + i}>NE</Option>
                                        <Option value="NO" key={"NO" + i}>NO</Option>
                                        <Option value="SE" key={"SE" + i}>SE</Option>
                                        <Option value="SO" key={"SO" + i}>SO</Option>
                                    </Select>
                                </Form.Item></Col>
                        </Row>
                    </>
                })}
                {/* MUROMEDIA */}

                <Row>
                    <Col span={6}>
                        <Title level={5}>Muro (Medianera)</Title>
                    </Col>
                    <Col span={18} >
                        <Space wrap style={{ float: 'right' }}>

                            <Button type="primary" size="small" onClick={removeMuroMedia} >
                                <MenosIcon />
                            </Button>
                            <Button type="primary" size="small" onClick={addMuroMedia}>
                                <MasIcon />
                            </Button>
                        </Space>
                    </Col>

                </Row>
                {Array.from(Array(muroMedia), (e, i) => {
                    return <>
                        <Row>
                            <Col span={6}>
                                <Form.Item initialValue={"M" + (i + 1)} name={"nombremuromedia" + i} label="Nombre" rules={[{ required: true }]}>
                                    <Input ></Input>
                                </Form.Item></Col>
                            <Col span={6}>
                                <Form.Item name={"longitudmuromedia" + i} label="Longitud" rules={[{ required: true, pattern: /^[+]?\d+([.]\d+)?$/, message: "Un numero positivo es requerido" }]}>
                                    <Input type="number"></Input>
                                </Form.Item></Col>
                            <Col span={6}>
                                <Form.Item name={"anchuramuromedia" + i} label="Anchura" rules={[{ required: true, pattern: /^[+]?\d+([.]\d+)?$/, message: "Un numero positivo es requerido" }]}>
                                    <Input type="number"></Input>
                                </Form.Item></Col>
                        </Row>
                    </>
                })}
                {/* HUECO */}

                <Row>
                    <Col span={6}>
                        <Title level={5}>Hueco</Title>
                    </Col>
                    <Col span={18} >
                        <Space wrap style={{ float: 'right' }}>

                            <Button type="primary" size="small" onClick={removeHueco} >
                                <MenosIcon />
                            </Button>
                            <Button type="primary" size="small" onClick={addHueco}>
                                <MasIcon />
                            </Button>
                        </Space>
                    </Col>

                </Row>
                {Array.from(Array(huecos), (e, i) => {
                    return <>
                        <Row>
                            <Col span={4}>
                                <Form.Item initialValue={"H" + (i + 1)} name={"nombrehueco" + i} label="Nombre" rules={[{ required: true }]}>
                                    <Input ></Input>
                                </Form.Item></Col>
                            <Col span={4}>
                                <Form.Item name={"asociadohueco" + i} initialValue="F1" label="Asociado" rules={[{ required: true }]}>
                                    <Select showSearch placeholder=""  >
                                        {Array.from(Array(muroFacha), (e, i) => {
                                            return <>
                                                <Option value={"F" + (i + 1)} key={"F" + i}>F{i + 1}</Option>
                                            </>
                                        })}
                                    </Select>
                                </Form.Item></Col>
                            <Col span={4}>
                                <Form.Item name={"longitudhueco" + i} label="Longitud" rules={[{ required: true, pattern: /^[+]?\d+([.]\d+)?$/, message: "Un numero positivo es requerido" }]}>
                                    <Input type="number"></Input>
                                </Form.Item></Col>
                            <Col span={4}>
                                <Form.Item name={"anchurahueco" + i} label="Anchura" rules={[{ required: true, pattern: /^[+]?\d+([.]\d+)?$/, message: "Un numero positivo es requerido" }]}>
                                    <Input type="number"></Input>
                                </Form.Item></Col>
                            <Col span={4}>
                                <Form.Item name={"tipovidriohueco" + i} initialValue="Simple" label="Vidrio" rules={[{ required: true }]}>
                                    <Select showSearch placeholder=""  >
                                        <Option value="Simple" key={"simple" + i}>Simple</Option>
                                        <Option value="Doble" key={"doble" + i}>Doble</Option>
                                        <Option value="Doble bajo emisivo" key={"doblebajo" + i}>Doble bajo emisivo</Option>
                                    </Select>
                                </Form.Item></Col>
                            <Col span={4}>
                                <Form.Item name={"tipomarcohueco" + i} initialValue="Metálico" label="Marco" rules={[{ required: true }]}>
                                    <Select showSearch placeholder=""  >
                                        <Option value="Metálico sin RTP" key={"metalico" + i}>Metálico</Option>
                                        <Option value="Metálico con RTP" key={"metalicortp" + i}>Metálico RTP</Option>
                                        <Option value="PVC" key={"pvc" + i}>PVC</Option>
                                        <Option value="Madera" key={"madera" + i}>Madera</Option>
                                    </Select>
                                </Form.Item></Col>
                        </Row>
                    </>

                })}
                <Title level={5}>Puente térmico</Title>
                <Form.Item name="puentetermico" label="" initialValue={defaultCheckedList}>
                    <Checkbox.Group style={{ width: '100%' }} >
                        <Row>
                            <Col span={8}>
                                <Checkbox value="Pilar integrado en fachada">Pilar integrado en fachada</Checkbox>
                            </Col>
                            <Col span={8}>
                                <Checkbox value="Pilar en esquina">Pilar en esquina</Checkbox>
                            </Col>
                            <Col span={8}>
                                <Checkbox value="Contorno de hueco">Contorno de hueco</Checkbox>
                            </Col>
                            <Col span={8}>
                                <Checkbox value="Caja de persiana">Caja de persiana</Checkbox>
                            </Col>
                            <Col span={8}>
                                <Checkbox value="Encuentro de fachada con forjado">Encuentro de fachada con forjado</Checkbox>
                            </Col>
                            <Col span={8}>
                                <Checkbox value="Encuentro de fachada con cubierta">Encuentro de fachada con cubierta</Checkbox>
                            </Col>
                            <Col span={8}>
                                <Checkbox value="Encuentro de fachada con suelo en contacto con el aire">Encuentro de fachada con suelo en contacto con el aire</Checkbox>
                            </Col>
                            <Col span={8}>
                                <Checkbox value="Encuentro de fachada con solera">Encuentro de fachada con solera</Checkbox>
                            </Col>
                        </Row>
                    </Checkbox.Group>
                </Form.Item>
            </Show>
            <Show title="" headerProps={{ backIcon: false }} breadcrumb={<></>} headerButtons={<></>}>
                <Title level={3}>Medidas de mejora</Title>
                <Form.Item name="medidasmejora" label="" initialValue={defaultCheckedList2} validateStatus={customStatus} help={customHelp} hasFeedback={customFeedback}>
                    <Checkbox.Group style={{ width: '100%' }} onChange={medidasValidation}>
                        <Row>
                            <Col span={8}>
                                <Checkbox value="Adición de aislamiento térmico en fachada por el exterior">Adición de aislamiento térmico en fachada por el exterior</Checkbox>
                            </Col>
                            <Col span={8}>
                                <Checkbox value="Sustitución de ventanas">Sustitución de ventanas</Checkbox>
                            </Col>
                            <Col span={8}>
                                <Checkbox disabled value="Incorporación/mejora de sistema fotovoltaico">Incorporación/mejora de sistema fotovoltaico</Checkbox>
                            </Col>
                            <Col span={8}>
                                <Checkbox value="Sustitución de calderas de combustión por otras de mayor eficiencia energética">Sustitución de calderas de combustión por otras de mayor eficiencia energética</Checkbox>
                            </Col>
                        </Row>
                    </Checkbox.Group>
                </Form.Item>

            </Show>
            <Show title="" headerProps={{ backIcon: false }} breadcrumb={<></>} headerButtons={<></>}>
                <Title level={3}>Instalaciones</Title>
                <Title level={5}>Tipo de instalacion</Title>
                <Form.Item name="tipodeinstalacion" label="" initialValue="Equipo de ACS" rules={[{ required: true, message: "Tipo de instalacion requerido" }]}>
                    <Select showSearch placeholder="Seleccionar tipo de edificio" >
                        <Option key="Equipo de ACS">Equipo de ACS</Option>
                        <Option key="Equipo mixto de calefacción y ACS">Equipo mixto de calefacción y ACS</Option>
                        <Option key="Equipo mixto de calefacción, refrigeración y ACS">Equipo mixto de calefacción, refrigeración y ACS</Option>
                    </Select>
                </Form.Item>
                <Title level={5}>Tipo de combustible</Title>
                <Form.Item name="tipodecombustible" label="" initialValue="Gas Natural" rules={[{ required: true, message: "Tipo de instalacion requerido" }]}>
                    <Select showSearch placeholder="Seleccionar tipo de combustible" >
                        <Option key="Gas Natural">Gas Natural</Option>
                        <Option key="Gasóleo-C">Gasóleo-C</Option>
                        <Option key="Electricidad">Electricidad</Option>
                        <Option key="GLP">GLP</Option>
                        <Option key="Carbón">Carbón</Option>
                        <Option key="Biocarburante">Biocarburante</Option>
                        <Option key="Biomasa no densificada">Biomasa no densificada</Option>
                        <Option key="Biomasa densificada (pelets)">Biomasa densificada (pelets)</Option>
                    </Select>
                </Form.Item>
                <Form.Item name="conacumulacion" label="" initialValue={false} valuePropName="checked">
                    <Checkbox value="Con acumulación">Con Acumulación</Checkbox>
                </Form.Item>
                <Title level={5}>Contribuciones energéticas</Title>
                <Form.Item name="concontribuciones" label="" initialValue={false} valuePropName="checked">
                    <Checkbox value="Con contribuciones energéticas">Con contribuciones energéticas</Checkbox>
                </Form.Item>
            </Show>
            <Show title="" headerProps={{ backIcon: false }} breadcrumb={<></>} headerButtons={<></>}>
                <Title level={3}>Otros</Title>
                <Title level={5}>Instalaciones fotovoltaicas</Title>
                <Form.Item name="previoyposterior" label="" initialValue={previo} valuePropName="checked">
                    <Checkbox onChange={changePrevio} value="Generar certificado previo y posterior">Generar certificado previo y posterior</Checkbox>
                </Form.Item>
                <Form.Item name="fechavisita" label="Fecha de visita previa" rules={[{ required: previo, message: "Fecha de visita previa necesaria" }]}>
                    <DatePicker format={"DD-MM-YYYY"} disabled={!previo}/>
                </Form.Item>
            </Show>
            <Show title="" headerProps={{ backIcon: false }} breadcrumb={<></>} headerButtons={<></>}>
                <Title level={5}>{"Estado: " + mensaje}</Title>
            </Show>
            <Show title="" headerProps={{ backIcon: false }} breadcrumb={<></>} headerButtons={<></>}>
                <Row>
                    <Col span={6}>
                        <Form.Item style={{ float: 'left' }} >
                            <Button id="generarButton" type="primary" htmlType="submit" loading={loading} style={{ marginRight: 10 }}>
                                Generar
                            </Button>
                            <Button id="generarButton" loading={registrarloading} onClick={registrarCertificado}>
                                Registrar
                            </Button>
                        </Form.Item>
                    </Col>
                    <Col span={3}>

                    </Col>
                    <Col span={15} >

                        <Form.Item style={{ float: 'right' }}>
                            <Button id="botonremoto" onClick={() => openLinkInNewTab('http://autoce3x.uksouth.cloudapp.azure.com:9000/vnc.html')} style={{ marginRight: 10 }}>
                                Control Remoto
                            </Button>
                            <Button id="generarButton" danger onClick={() => generarPayload(true)}>
                                Reportar
                            </Button>

                        </Form.Item>
                    </Col>
                </Row>
            </Show>

        </Form>
    );
};

