
import { Show } from "@refinedev/antd";

import { Typography, Form, Input, Select, Button } from "antd";
import layout from "antd/es/layout";
import { DefaultOptionType } from "antd/es/select";
import axios from "axios";
import {fetchMunicipios, fetchProvincias, fetchVerificarRC} from "catastro/functions";
import React, { useEffect } from "react";
import { Link, generatePath, redirect, useNavigate } from "react-router-dom";


const { Title, Text } = Typography;
const { Option } = Select;

export const SeguridadEstructural: React.FC = () => {
    const [form] = Form.useForm();
    let navigate = useNavigate();
    const [provincias, setProvincias] = React.useState<string[]>([])
    const [municipios, setMunicipios] = React.useState<string[]>([])
    const [direccion, setDireccion] = React.useState<string>()
    const [cp, setCp] = React.useState<boolean>(false)
    const [cpstring, setCpstring] = React.useState<string>("")

    useEffect(() => {
        fetchProvincias().then(provincias => setProvincias(provincias))
    }, [])

    const onProvinciaChange = (value: string) => {
        fetchMunicipios(value).then(municipios => setMunicipios(municipios))
    };

    const verificarDatos = (values: any) => {
        fetchVerificarRC(form.getFieldValue("provincia"), form.getFieldValue("municipio"), form.getFieldValue("referenciacatastral")).then(result  => {
            if (result.error) {
                setDireccion(result.msg)
                document.getElementById("comprobartexto")!.removeAttribute("hidden")
                document.getElementById("botoncontinuar")!.setAttribute("hidden", "true")
            }else{
                setDireccion(result.msg)
                
                document.getElementById("comprobartexto")!.removeAttribute("hidden")
                if (!(result.cpdetected)){
                    setCp(true)
                    document.getElementById("comprobarcp")!.removeAttribute("hidden")
                    document.getElementById("cp")!.removeAttribute("hidden")
                }else{
                    document.getElementById("botoncontinuar")!.removeAttribute("hidden")
                }
                
            }
})};
   
const medidasValidation = () => {
    if (form.getFieldValue("cp")?.length === 5) {
        document.getElementById("botoncontinuar")!.removeAttribute("hidden")
        setCpstring(form.getFieldValue("cp"))
    }else{
        document.getElementById("botoncontinuar")!.setAttribute("hidden", "true")
    }
}

    return (
        <Form
            {...layout}
            form={form}
            name="control-hooks"
            style={{ maxWidth: 1000 }}
            autoComplete="on"
            onFinish={verificarDatos}
        >
            <Show
                title="Generar nuevo certificado de seguridad estructural"
                headerProps={{
                    backIcon: false,
                }}
                headerButtons={<></>}
            >
                <Title level={5}>Provincia</Title>
                <Form.Item name="provincia" label="" rules={[{ required: true, message: "Provincia requerido" }]}>
                    <Select showSearch
                        placeholder="Seleccionar provincia"
                        onChange={onProvinciaChange}
                        allowClear
                    >
                        {provincias.map((provincia) => (
                            <Option key={provincia} value={provincia}>{provincia}</Option>
                        ))}
                    </Select>
                </Form.Item>

                <Title level={5}>Municipio</Title>
                <Form.Item name="municipio" label="" rules={[{ required: true, message: "Municipio requerido" }]}>
                    <Select showSearch
                        placeholder="Seleccionar municipio"
                        allowClear
                    >
                        {municipios.map((municipio) => (
                            <Option key={municipio} value={municipio}>{municipio}</Option>
                        ))}
                    </Select>
                </Form.Item>

                <Title level={5}>Referencia catastral</Title>
                <Form.Item name="referenciacatastral" label="" rules={[{ required: true, message: "Referencia catastral requerida" }, { len: 20, message: "La referencia catastral debe tener 20 caracteres" }]}>
                    <Input />
                </Form.Item>
                <Form.Item >
                    <Button type="primary" htmlType="submit">
                        Verificar
                    </Button>
                </Form.Item>
                <Title id="comprobartexto" hidden level={5}>Comprobar dirección</Title>
                <Form.Item name="direccion" >
                    <Text >{direccion}</Text>
                </Form.Item>
                <Title id="comprobarcp" hidden style={{ color: 'red' }} level={5}>Código postal no detectado</Title>
                <Form.Item name="cp" >
                    <Input style={{ width: '20%' }} onChange={medidasValidation} maxLength={5} type="number" hidden id="cp"></Input>
                </Form.Item>
                <Form.Item >
                <Link to={"/seguridadestructural-2?provincia="+form.getFieldValue("provincia")+"&municipio="+form.getFieldValue("municipio")+"&rc="+form.getFieldValue("referenciacatastral")+"&cp="+cpstring}>
                    <Button id="botoncontinuar" type="primary" hidden >
                        Continuar
                    </Button>
                    </Link>
                </Form.Item>
            </Show>
        </Form>
        
    );
};
