import React from "react";
import { IResourceComponentsProps, BaseRecord, useMany } from "@refinedev/core";
import {
    useTable,
    List,
    EditButton,
    ShowButton,
    TagField,
    EmailField,
    DeleteButton,
} from "@refinedev/antd";
import { Table, Space, Form, Input, Button } from "antd";
import { Link } from "react-router-dom";

export const ClienteList: React.FC<IResourceComponentsProps> = () => {
    const { tableProps, searchFormProps } = useTable({
        syncWithLocation: true,
        pagination: {
            pageSize: 10,
        },
        onSearch: (values: any) => {
            return [
                {
                    field: "search",
                    operator: "contains",
                    value: values.search,
                },
            ];
        },
    });

    return (
        <List>
            <Form {...searchFormProps} layout="inline" style={{ marginBottom: 15 }}>
                <Form.Item name="search">
                    <Input placeholder=" Introducir busqueda" />
                </Form.Item>
                <Button type="primary" onClick={searchFormProps.form?.submit}>Buscar</Button>
            </Form>
            <Table {...tableProps} rowKey="id" pagination={{
                ...tableProps.pagination,
                position: ["bottomCenter"],
                size: "default",
                showQuickJumper: {
                    goButton: <Button type="primary" style={{ marginLeft: 10 }}>Ir</Button>,
                },
            }}>
            <Table.Column
                    title="Acciones"
                    dataIndex="actions"
                    render={(_, record: BaseRecord) => (
                        <Space>
                            <ShowButton
                                hideText
                                size="small"
                                recordItemId={record._id}
                            />
                            <EditButton
                                hideText
                                size="small"
                                recordItemId={record._id}
                            />
                            <DeleteButton
                                hideText
                                size="small"
                                recordItemId={record._id}
                            />
                            
                        </Space>
                    )}
                />
                <Table.Column dataIndex="razon_social" title="Razón Social" />
                <Table.Column dataIndex="nif" title="NIF" />
                <Table.Column dataIndex="direccion" title="Dirección" />
                <Table.Column dataIndex="numero" title="Número" />
                <Table.Column dataIndex="ciudad" title="Ciudad" />
                <Table.Column dataIndex="provincia" title="Provincia" />
                <Table.Column dataIndex="pais" title="Pais" />
                <Table.Column dataIndex={["telefono"]} title="Teléfono" />
                <Table.Column dataIndex={["enlaceauto"]} title="Abrev." render={(
                    value: string,
                    record: BaseRecord,
                ) => (
                    <Link to={"https://www.certinae.es/solicitud/"+value}>{value}</Link>

                )}/>
                
            </Table>
        </List>
    );
};
