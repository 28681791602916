import React from "react";
import { IResourceComponentsProps, useShow, useOne } from "@refinedev/core";
import { Show, TagField, TextField, EmailField } from "@refinedev/antd";
import { Checkbox, Divider, InputNumber, Space, Typography } from "antd";
import TextArea from "antd/es/input/TextArea";
import { Link } from "react-router-dom";

const { Title } = Typography;

export const ClienteShow: React.FC<IResourceComponentsProps> = () => {
    const { queryResult } = useShow();
    const { data, isLoading } = queryResult;

    const record = data?.data;

    return (
        <Show isLoading={isLoading} canDelete title="Detalles del cliente">
            <Title level={4}>Datos del cliente:</Title>
             <Title level={5}>Razon Social</Title>
            <TextField value={record?.razon_social} />
            <Title level={5}>NIF/CIF</Title>
            <TextField value={record?.nif} />
            <Title level={5}>Direccion</Title>
            <TextField value={record?.direccion} />
            <Title level={5}>Número</Title>
            <TextField value={record?.numero} />
            <Title level={5}>Codigo Postal</Title>
            <TextField value={record?.codigo_postal} />
            <Title level={5}>Ciudad</Title>
            <TextField value={record?.ciudad} />
            <Title level={5}>Provincia</Title>
            <TextField value={record?.provincia} />
            <Title level={5}>Pais</Title>
            <TextField value={record?.pais} />
            <Title level={5}>Telefono</Title>
            <TextField value={record?.telefono} />
            <Title level={5}>Email</Title>
            <EmailField value={record?.email} />
            <Title level={5}>Abrev.</Title>
            <Link to={"https://www.certinae.es/solicitud/"+record?.enlaceauto}>{record?.enlaceauto}</Link>
            <Divider />
            <Title level={4}>Opciones de facturación:</Title>
            <Title level={5}>Precio CEE:</Title>
            {record?.precio_cee
             ? <TextField value={record?.precio_cee + " €"} />
                : <TextField value={"[Precio por defecto]"} />
            }<br/><br/>
            <Checkbox checked={record?.saltar_tasa} > Saltar tasa de registro CEE (La tasa de registro siempre sera 0.00€ para este cliente)</Checkbox>
            <Divider />
            <Title level={4}>Notas</Title>
            <TextArea value={record?.notas} />
        </Show>
    );
};
